import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "gatsby-material-ui-components";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "solid 1px silver",
    borderRadius: "5px",
  },
  mobile: {
    marginBottom: theme.spacing(4),
  },
  em: {
    color: theme.palette.primary.light,
  },
  localisation: {
    color: theme.palette.primary.dark,
    marginLeft: "11px",
  },
  title: {
    borderLeft: `solid 4px ${theme.palette.primary.light}`,
    fontSize: "1.2em",
    fontWeight: "bold",
    paddingBottom: 0,
    minHeight: 90,
  },
  titleCdi: {
    borderLeft: `solid 4px silver`,
  },
  borderCdi: {
    borderColor: theme.palette.primary.light,
  },
  label: {
    padding: 0,
    fontSize: "0.9em",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    borderRadius: "5px",
  },
  growth: {
    color: "#82acfe",
  },
  business_team: {
    color: theme.palette.primary.dark,
  },
  description: {
    color: theme.palette.primary.dark,
  },
  button: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  enLight: {
    color: theme.palette.primary.light,
  },
}));

const Offre = ({
  title,
  description,
  localisation,
  jobType,
  matches,
  langKey,
}) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      lg={4}
      className={clsx({ [classes.root]: false, [classes.mobile]: !matches })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Grid
        container
        direction="column"
        alignItems="stretch"
        spacing={2}
        className={clsx(classes.root, {
          [classes.borderCdi]:
            localisation && localisation.indexOf("CDI") !== -1,
        })}
      >
        <Grid
          xs={12}
          item
          className={clsx(classes[jobType], classes.label, {
            [classes.enLight]: hovered,
            [classes.topBe]: true,
          })}
        >
          {jobType === "business_team" ? "Business team" : "Customer success"}
        </Grid>
        <Grid item xs={12} className={classes.title}>
          {title}
          <br />
          <span
            className={clsx(classes.description, {
              [classes.enLight]: hovered,
            })}
          >
            {description}
          </span>
        </Grid>
        <Grid
          item
          style={{ paddingTop: 0, minWidth: "80%" }}
          container
          direction="column"
          spacing={3}
        >
          <Grid item className={classes.localisation} xs={11}>
            {localisation}
          </Grid>
          <Grid item className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              href="https://www.welcometothejungle.com/fr/companies/cyclope/jobs"
              target="_blank"
            >
              {langKey === "fr" ? "Postuler" : "Apply"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Offre.propTypes = {
  title: PropTypes.string.isRequired,
  langKey: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  localisation: PropTypes.string.isRequired,
  jobType: PropTypes.string.isRequired,
  matches: PropTypes.bool.isRequired,
};

export default Offre;
